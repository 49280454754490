import React from 'react';
import ReactHtmlParser from 'react-html-parser';

// const ReactMarkdown = require("react-markdown");

export default function SubTitle_Title_Content(props) {
  return (
    <>
      <div className={` ${props.className}`}>
        <div className="row pb-10">
          <div className="col-lg-12">
            <div
              className=" flex flex-wrap items-center font-bold"
              style={{
                color: props.color ? props.color : '#0eb29f',
                // cursor: "pointer",
              }}
            >
              <h6 className=" font-bold">{props.subTitle}</h6>
            </div>
            {props.title ? (
              <h3 className=" mb-3">{ReactHtmlParser(props.title)}</h3>
            ) : (
              ''
            )}
            <div className="bodyText1">{ReactHtmlParser(props.content)}</div>
            <div>{props.children}</div>
          </div>{' '}
        </div>
      </div>
    </>
  );
}
