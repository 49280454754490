import ApplyNowCTA from '@components/Common/ApplyNowCTA/index';
import Card from '@components/Card/index';
import SEO from '@components/Common/SEO/index';
import useResizer from '@components/extra/useResizer';
import Footer from '@components/Footer/index';
import Grid_ImageLeft_ContentRight from '@components/sections/Grid_ImageLeft_ContentRight/index';
import SubTitle_Title_Content from '@components/SubTitle_Title_Content/index';
import React, { useContext, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import './index.css';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import 'swiper/css';
import { default as IBDNavigation } from '@components/Navigation';
import { useLocation } from '@reach/router';
SwiperCore.use([Navigation, Autoplay]);

let str = '';

export default function Challenge({ data, pageContext }) {
  const images = data.allFile.nodes;
  const sale = data.strapiSale.SaleID;

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const [emailForm, setemailForm] = useState(true);
  const [email, setEmail] = useState('');
  const [applyNowHero, setApplyNowHero] = useState(false);
  const formRef = useRef(null);

  const location = useLocation();

  // redirect Chanllenge page until its in use again
  // useEffect(() => {
  //   if (window) {
  //     window.location.replace(
  //       '/software-development-fellowship-program/' + location?.search,
  //     );
  //   }
  // }, []);

  const calculateTimeLeft = () => {
    const difference = +new Date('2020-07-24 12:00') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        // seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTimeLeft(calculateTimeLeft());
  //   }, 1000);
  // });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{' '}
      </span>,
    );
  });

  const isMobile = useResizer();
  const advocates = [
    {
      name: 'Amogh Rajesh Desai',
      image: '01_Amogh',
      college: 'PES University, Bangalore',
    },
    {
      name: 'Archit Dwij',
      image: '02_Archith',
      college: 'SCSVMV University, Kanchipuram',
    },
    {
      name: 'Vishnu Kalyan',
      image: '03_Vishnu',
      college: 'Saveetha Engineering College, Nellore',
    },
    {
      name: 'A P Akshaya',
      image: '04_AP',
      college: 'Rajalakshmi Engineering College, Chennai',
    },
    {
      name: 'Suraj Jitendra Tiwari',
      image: '05_Suraj',
      college: 'St. John College of Engineering & Management, Mumbai',
    },
    {
      name: 'Vipul Krishna Mathuria',
      image: '06_Vipul',
      college: 'NIT Jalandhar',
    },
    ,
    {
      name: 'Samyak Jain',
      image: '07_Samyak',
      college: 'IET, DAVV',
    },
    {
      name: 'Shuvam Manna',
      image: '08_Shuvam',
      college: 'Heritage Institute of Technology, Kolkata',
    },
    {
      name: 'Sudhanshu Tiwari',
      image: '09_Sudhanshu',
      college: 'Kendriya Vidyalaya No.1, Kota',
    },
    {
      name: 'Archana Kumari',
      image: '10_Archana',
      college: 'Accurate Institute of Management and Technology, Greater Noida',
    },
    {
      name: 'Rohit Ghumare',
      image: '11_Rohit',
      college: 'Datta Meghe College of Engineering, Thane',
    },
    {
      name: 'Aishwarya R',
      image: '12_Aishwarya',
      college: 'SASTRA University , Kumbakonam',
    },
    {
      name: 'Rishabh Sharma',
      image: '13_Rishabh',
      college: 'JIET College of Engineering, Jodhpur',
    },
    {
      name: 'Dhruv Bindra',
      image: '14_Dhruv',
      college: 'PES Institute of Technology, Bangalore',
    },
  ];
  const speakerData = [
    {
      image: 'Agenda-01',
      schedule: 'Dec 17 - 11 AM IST',
      level: 'Speaker Session',
      speaker: 'Kiran Suresh',
      avatar: 'KiranS',
      designation: 'Lead Product Engineer, Crio.Do | Ex-Motorola',
      topic: 'How to Learn-Build-Inspire',
      description: 'Kickoff and Event Walkthrough Session',
    },
  ];
  const challengeData = [
    {
      image: 'Agenda-02',
      schedule: 'Start: Dec 17 - 3 PM IST',
      topic: 'Task 1: Build short learning modules of your choice',
      description:
        'Choose from the listed topics to create your first learn-by-doing module with our guidance.',
    },
    {
      image: 'Agenda-03',
      schedule: 'Deadline: Dec 25 - 11:59 PM IST',
      topic: 'Task 2: Share your experience with the world',
      description: `Write a blog on Medium/Dev.to about what you learned while building the module. Don’t forget to popularize your blog/video as that will decide your winning points.`,
    },
  ];
  const criteriaData = [
    {
      id: 1,
      image: 'Criteria-01',
      description: 'Join the Kickoff and Event walkthrough Session',
    },
    {
      id: 2,
      image: 'Criteria-02',
      description: 'Complete all tasks',
    },
    {
      id: 3,
      image: 'Criteria-03',
      description: 'Get maximum likes and comments for your work',
    },
  ];
  const pastWinnersData = [
    {
      id: 1,
      name: 'Deepika Juneja',
      image: 'Deepika',
      college: 'Harcourt Butler Technical University',
    },
    {
      id: 2,
      name: 'Adarsh TS',
      image: 'Adarsh',
      college: 'SRM University',
    },
    {
      id: 3,
      name: 'Simran Kumari',
      image: 'Simran',
      college: 'Narula Institute of Technology ',
    },
    {
      id: 4,
      name: 'Eram Naaz',
      image: 'Eram',
      college: 'University of Engineering and Management',
    },
    {
      id: 5,
      name: 'Abhinav K Gupta',
      image: 'Abhinav',
      college: 'HMR Institute of Technology & Management',
    },
  ];

  // return (
  //   <div className="flex h-screen w-screen items-center justify-center">
  //     <Loader></Loader>
  //   </div>
  // );

  const settings = {
    /* slidesPerColumn: isMobile ? 3 : 2,
		slidesPerColumnFill: 'row', */
    slidesPerView: isMobile ? 1 : 3,
    centeredSlides: false,
    // waitForTransition: false,
    // freeMode: true,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
    speed: 3000,
    allowTouchMove: true,
    grabCursor: true,
  };
  let footerData = images.find((val) => val.name == 'Pre-Footer');
  let footerImage = getImage(footerData.childImageSharp);
  return (
    <>
      <SEO
        title="Crio #IBelieveinDoing - Learning Bytes Edition"
        description="Build short learning modules that assist developers to learn tech skills in an applied way."
      ></SEO>
      {/* <div className="fixed-header">
        <PromotionBanner sale={sale} location={'IBD'}></PromotionBanner>
        <Header scrolled={true}></Header>{' '}
      </div> */}
      <IBDNavigation />

      <div id="ibd-page">
        <div
          className={`relative flex justify-center bg-ibd-blue-400 md:max-h-[1300px] md:overflow-hidden`}
        >
          <div className=" h-full md:w-[1300px]">
            {' '}
            {!isMobile && (
              <StaticImage
                loading="lazy"
                src="../../images/IBD/Desktop-Hero.png"
                placeholder="blurred"
                alt="Hero"
                width={1300}
                layout="fullWidth"
                className="z-0 mt-20"
              />
            )}
          </div>
          <div className=" left-0  right-0 z-10 flex flex-col items-center justify-center pt-48 pb-20 text-white  md:absolute md:top-10 md:pt-20">
            <StaticImage
              loading="lazy"
              src="../../images/IBelieveInDoing-Logo.png"
              placeholder="blurred"
              alt="IBD Logo"
              width={250}
            />
            <div className="flex flex-col items-center justify-center gap-2">
              <p>Learning Bytes Edition</p>
              <div className="mb-8 flex flex-col items-center justify-center text-center md:mb-0">
                <h1 className="mt-5 text-4xl font-bold text-white md:text-5xl md:leading-loose">
                  Learn-Build-Inspire
                </h1>

                <p className="mt-5 text-xl font-light text-ibd-purple-100">
                  <span className="font-bold text-[#0CD9C7]">Build </span>
                  learn-by-doing tech modules
                  <br /> <span className="font-bold text-[#0CD9C7]">
                    Win
                  </span>{' '}
                  rewards
                  <br />{' '}
                  <span className="font-bold text-[#0CD9C7]">Launch</span> your
                  career
                </p>
                <h3 className="mb-6 mt-8 hidden text-2xl text-[#B5B7F1] md:block">
                  {' '}
                  Dec 17 - Dec 25
                </h3>
              </div>{' '}
              <div className="">
                <ApplyNowCTA
                  width={150}
                  link={'phWCkzPa'}
                  location="IBD_HERO"
                  type="IBD"
                  text="Register Now"
                  innerText="Register Now"
                  className="bg-ibd-primary-100 text-white"
                  applyNowInsideClassName="bg-ibd-primary-100 text-white"
                  openEvent="CHALLENGE_DETAIL_REGISTER_NOW_CLICKED"
                ></ApplyNowCTA>
              </div>
            </div>
            {/* <StaticImage loading="lazy"
              src="../../images/IBD/Mobile-Hero.png"
              placeholder="blurred"
              alt="Hero"
              width={300}
              className="z-20 absolute bottom-0 left-0 right-0"
            /> */}
          </div>
        </div>

        <div>
          <div className="bg-ibd-blue-50 pt-10">
            <div className="container">
              <div className="row pb-20">
                <div className="col-lg-12">
                  <article className="flex flex-col items-center justify-center text-crio-neutral-900">
                    <div className="flex flex-col gap-4 md:w-3/4">
                      <p>
                        We are excited to be back with the latest edition of
                        <span className="font-bold text-ibd-blue-300">
                          {' '}
                          #IBelieveinDoing!
                        </span>
                      </p>
                      <p>
                        Here’s an opportunity to create Awesome Experiential
                        Learning content to help 1000s learn tech skills through
                        practical activities.
                      </p>
                      <p className="">
                        {' '}
                        Grab this chance to walk away with assured recognition,
                        exciting rewards, and a chance to intern with Crio which
                        could later be converted to a PPO :)
                      </p>
                    </div>
                    <div className="flex flex-col text-center md:w-3/4">
                      <p className="mt-10 mb-9 text-3xl font-bold leading-10 text-ibd-blue-300">
                        Are you ready for this exciting #IBelieveinDoing
                        challenge?
                      </p>
                      <ApplyNowCTA
                        width={150}
                        link={'phWCkzPa'}
                        innerText="Register Now"
                        location="IBD_ARE_YOU_READY"
                        type="IBD"
                        text="Register Now"
                        className="bg-ibd-primary-100 text-white"
                        applyNowInsideClassName="bg-ibd-primary-100 text-white"
                        openEvent="CHALLENGE_DETAIL_REGISTER_NOW_CLICKED"
                      ></ApplyNowCTA>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-ibd-blue-25 pt-10 pb-10">
            <div className="container">
              {/* <StaticImage
                loading="lazy"
                style={{
                  width: isMobile ? '28px' : '88px',
                  height: isMobile ? '56px' : '172px',
                }}
                className="mb-3 -mt-28 absolute right-24 md:right-48 md:-mt-40 lg:right-96"
                src="../../images/IBD/Element.png"
                placeholder="blurred"
                alt="Motif"
              /> */}
              {/* <Image
                filename="Element"
                style={{
                  width: isMobile ? '28px' : '88px',
                  height: isMobile ? '56px' : '172px',
                }}
                className="mb-3 -mt-28 absolute right-24 md:right-48 md:-mt-40 lg:right-96"
              ></Image> */}
              <SubTitle_Title_Content
                className="text-center text-ibd-blue-400"
                title="Agenda"
              >
                {speakerData.map((ele, idx) => {
                  let data = images.find((val) => val.name == ele.avatar);
                  let avatar = getImage(data.childImageSharp);
                  data = images.find((val) => val.name == ele.image);
                  let image = getImage(data.childImageSharp);
                  return (
                    <Card
                      image={image}
                      className="rounded-2xl bg-ibd-blue-400"
                      key={idx}
                    >
                      <div className="flex flex-col items-start">
                        <h6 className="mb-4 font-bold text-ibd-blue-100">
                          {ele.schedule}{' '}
                        </h6>
                        <h4 className="p-0 text-left text-white">
                          {ele.topic}
                        </h4>
                        <div className="mt-4 mb-4 font-semibold text-ibd-blue-50">
                          {ReactHtmlParser(ele.description)}
                        </div>
                        <div className="flex items-center justify-center gap-2 rounded-lg bg-ibd-blue-300 p-2 pr-4">
                          <div className="flex items-center justify-center rounded-full bg-ibd-blue-400 p-2">
                            <GatsbyImage
                              loading="lazy"
                              image={avatar}
                              alt={'IBD'}
                              style={{
                                width: '54px',
                              }}
                            />
                            {/* <Image
                              filename={ele.avatar}
                              style={{
                                width: '54px',
                              }}
                            ></Image> */}
                          </div>
                          <div className="flex flex-col items-start">
                            <h5 className="font-bold text-ibd-blue-100">
                              {ele.speaker}
                            </h5>
                            <h6 className="font-normal text-white">
                              {ele.designation}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </SubTitle_Title_Content>
              <section className="pb-6">
                <h3 className="text-center text-crio-neutral-900">
                  Challenges
                </h3>
                {challengeData.map((ele, idx) => {
                  const data = images.find((val) => val.name == ele.image);
                  const image = getImage(data.childImageSharp);
                  return (
                    <Card
                      image={image}
                      className="rounded-2xl bg-ibd-blue-400"
                      key={idx}
                      imageName={ele.image}
                    >
                      <div className="flex flex-col items-start">
                        <h6 className="mb-4 font-bold text-ibd-blue-100">
                          {ele.schedule}{' '}
                        </h6>
                        <h4 className="p-0 text-left text-white">
                          {ele.topic}
                        </h4>
                        <div className="mt-4 mb-4 leading-8 text-ibd-blue-50">
                          {ReactHtmlParser(ele.description)}
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </section>
            </div>
          </div>
          <div style={{ backgroundColor: '#FDFAF7' }} className="pt-10 pb-12">
            <div className="container">
              <div className="mb-4 pt-6 pb-6">
                <h1 className="text-center text-4xl text-ibd-blue-400">
                  What's in it for you
                </h1>
                <p className="mt-9 text-center text-base font-normal">
                  Stand a chance to win{' '}
                  <span className="font-bold">Asus VivoBook 14!</span>
                </p>{' '}
              </div>
              <div className="col-lg-12">
                <div className="flex flex-col items-center justify-center gap-16 md:p-3">
                  <table id="rewards" style={{ width: '100%' }}>
                    <tr className="bg-ibd-purple-200 text-white">
                      <th className="bodyText border-r-0 font-bold uppercase">
                        Category
                      </th>
                      <th className="bodyText font-bold uppercase">Prize</th>
                    </tr>
                    <tr className="h-40">
                      <td className="bodyText text-center font-semibold">
                        Winner
                      </td>
                      <div
                        style={{ height: 'inherit' }}
                        className="flex flex-col justify-center"
                      >
                        <td className="bodyText flex flex-col items-center border-r-0">
                          <div>
                            <p>ASUS VivoBook 14</p>
                            <p>+</p>
                            <p>
                              25% Additional Scholarship to Crio signature
                              programs
                            </p>
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr className="h-40">
                      <td className="bodyText text-center font-semibold">
                        Top 2 Performers
                      </td>
                      <div
                        style={{ height: 'inherit' }}
                        className="flex flex-col justify-center"
                      >
                        <td className="bodyText flex flex-col items-center border-r-0">
                          <div>
                            <p>Skullcandy Wireless Over-Ear Headphones</p>
                            <p>+</p>
                            <p>
                              25% Additional Scholarship to Crio signature
                              programs
                            </p>
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr className="h-40">
                      <td className="bodyText text-center font-semibold">
                        Top 3-15 Performers
                      </td>
                      <div
                        style={{ height: 'inherit' }}
                        className="flex flex-col justify-center"
                      >
                        <td className="bodyText flex flex-col items-center border-r-0 md:-ml-48">
                          <div>
                            <p>₹ 5,000 Amazon gift voucher</p>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                  <p className="italic">
                    Also, top performers will get a PPI with Crio which can
                    later be converted to a PPO.
                  </p>
                  <div className="flex flex-col gap-16">
                    <h2 className="text-center text-ibd-blue-400">
                      What you need to do to get them
                    </h2>
                    <div className="flex flex-col flex-wrap items-center justify-start gap-16 md:flex-row md:flex-nowrap md:items-start">
                      {criteriaData.map((criteria) => {
                        const data = images.find(
                          (val) => val.name == criteria.image,
                        );
                        const image = getImage(data.childImageSharp);

                        return (
                          <div
                            key={criteria.id}
                            className="flex max-w-[12rem] flex-col items-center justify-center gap-4 text-center"
                          >
                            <GatsbyImage
                              loading="lazy"
                              image={image}
                              alt={'IBD'}
                              style={{ width: '150px' }}
                            />
                            {/* <Image
                              filename={criteria.image}
                              style={{ width: '150px' }}
                            ></Image> */}
                            <p
                              className="font-normal"
                              style={{ color: '#323232' }}
                            >
                              {criteria.description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-ibd-blue-25 pt-16 pb-12">
            <div className="container">
              <section className="flex flex-col text-center">
                <h2 className="mb-3 text-ibd-blue-400">
                  Highlights from the previous edition
                </h2>
                <div className="subtitle1 mb-10">
                  <p className="font-normal">
                    Check out our top Believers of Doing from the previous
                    edition of{' '}
                    <span className="font-bold text-ibd-blue-400">
                      #IBelieveinDoing!
                    </span>
                  </p>
                </div>
                <div className="winners-carousel pb-10">
                  {' '}
                  <Swiper
                    {...settings}
                    pagination={{ clickable: true }}
                    navigation
                  >
                    {pastWinnersData.map((ele, idx) => {
                      const data = images.find((val) => val.name == ele.image);
                      const image = getImage(data.childImageSharp);

                      return (
                        <SwiperSlide key={idx}>
                          <div className="flex flex-col items-center justify-center gap-4 text-center ">
                            <GatsbyImage
                              loading="lazy"
                              image={image}
                              alt={'IBD'}
                              style={{ width: '150px' }}
                              className="mb-3"
                            />
                            {/* <Image
                              filename={ele.image}
                              style={{ width: '150px' }}
                              className="mb-3"
                            ></Image> */}
                            <div className="mb-2 flex flex-col items-center justify-center gap-3">
                              <div>
                                <h5 className="text-ibd-blue-300">
                                  {ele.name}
                                </h5>
                              </div>{' '}
                              <div>
                                <h6 className="font-normal text-crio-neutral-700">
                                  {ele.college}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                  {/* <Carousel
                    infinite
                    slidesPerPage={isMobile ? 1 : 3}
                    slidesPerScroll={isMobile ? 1 : 3}
                    draggable={true}
                    arrows
                    dots
                  >
                    {pastWinnersData.map((ele) => (
                      <div className="flex flex-col gap-4 justify-center items-center text-center ">
                        <Image
                          filename={ele.image}
                          style={{ width: '150px' }}
                          className="mb-3"
                        ></Image>
                        <div className="flex flex-col justify-center items-center gap-3 mb-2">
                          <div>
                            <h5 className="text-ibd-blue-300">{ele.name}</h5>
                          </div>{' '}
                          <div>
                            <h6 className="text-crio-neutral-700 font-normal">
                              {ele.college}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel> */}
                </div>
              </section>{' '}
            </div>
          </div>
        </div>
        <Grid_ImageLeft_ContentRight
          bgColor="#0D153D"
          textColor="#fff"
          image={footerImage}
        >
          <div className="pt-24 pb-24">
            <div className="flex flex-col items-center gap-8 lg:items-start">
              <h2 className="mb-4 w-3/4 text-center font-bold lg:text-left">
                Are you ready to Learn-Build-Inspire?
              </h2>
              <ApplyNowCTA
                width={150}
                link={'phWCkzPa'}
                innerText="Register Now"
                location="IBD_FOOTER"
                type="IBD"
                text="Register Now"
                className="bg-ibd-primary-100 text-white"
                applyNowInsideClassName="bg-ibd-primary-100 text-white"
                openEvent="CHALLENGE_DETAIL_REGISTER_NOW_CLICKED"
              ></ApplyNowCTA>
            </div>
          </div>
        </Grid_ImageLeft_ContentRight>
        <Footer bgColor="#0D153D"></Footer>
      </div>
    </>
  );
}

export const pageQuery = graphql`
  query {
    strapiSale {
      ...strapiSaleFragment
    }

    allFile(filter: { relativePath: { regex: "images/IBD/" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;
