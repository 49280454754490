import React from 'react';
import * as styles from './Card.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Card(props) {
  return (
    <div
      className={`mt-6 mb-4 p-8  ${styles.card} ${props.className}`}
      style={{ ...props.cssOverrides, background: props.bgColor }}
    >
      <div className="row flex ">
        <div className="col-12 col-lg-2 flex  justify-center text-center ">
          <div className="mb-4  flex w-full  items-center justify-center text-center md:mb-0">
            {' '}
            <div
              className=" flex flex-col items-center justify-center"
              style={{ width: '100%' }}
            >
              <GatsbyImage
                loading="lazy"
                image={props.image}
                alt={'session'}
                style={{
                  width: '100%',
                  maxWidth: `${
                    props.imageName === 'Agenda-02' ? '120px' : '180px'
                  }`,
                }}
              />
              {/* <Image
                filename={props.image}
                style={{ width: '100%', maxWidth: '180px' }}
                // className="assetImage"
              ></Image> */}
            </div>
          </div>
        </div>
        <div className="col-lg-10 flex flex-col justify-center ">
          {props.children}
        </div>
      </div>
    </div>
  );
}
