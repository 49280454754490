import Image from '@components/extra/image';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import * as styles from './Grid_ImageLeft_ContentRight.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';

// const ReactMarkdown = require("react-markdown");

export default function Grid_ImageLeft_ContentRight(props) {
  return (
    <>
      <section
        className={`pt-5 pb-8 ${props.className}`}
        style={{ background: props.bgColor, color: props.textColor }}
      >
        <div className="container  ">
          <div className="row  flex flex-col items-center justify-center md:flex-row">
            <div className="col-12 col-sm-12  col-lg-4 ">
              <div
                className={`${styles.image}  flex flex-col items-center  justify-center`}
                style={{ width: props.imageWidth }}
              >
                <GatsbyImage
                  loading="lazy"
                  image={props.image}
                  alt={'Image'}
                  style={{ width: '60%' }}
                />
                {/* <Image
                  filename={props.image}
                  className="mb-2"
                  style={{ width: '60%' }}
                ></Image> */}
                {props.imageTwo ? (
                  <>
                    <h3>X</h3>
                    <Image
                      filename={props.imageTwo}
                      style={{ width: '80%' }}
                    ></Image>{' '}
                  </>
                ) : (
                  ''
                )}
                {props.date && (
                  <h5 className="mb-3 text-center  ">
                    {ReactHtmlParser(props.date)}
                  </h5>
                )}
                <p className="subtitle1 text-center font-light">
                  {ReactHtmlParser(props.imageCaption)}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-lg-8 flex flex-col  justify-center">
              {' '}
              {props.children}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
