import Image from '@components/extra/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React from 'react';
import * as styles from './footer.module.scss';

export default function Footer(props) {
  let str = '?utm_source=crio-website';

  return (
    <>
      <section
        className={`font-light ${styles.footer} ${props.className}`}
        style={{
          background: props.bgColor ? props.bgColor : '#031718',
        }}
      >
        <div className="bodyText2    container p-4 pt-16 pb-10 font-light">
          <div className="mb-8 grid grid-cols-1 gap-y-4 lg:grid-cols-5 lg:gap-x-8">
            <div>
              <div style={{ width: '150px' }}>
                <Image filename="Crio_Light.png"></Image>
              </div>
              <br></br>
              <div className="mb-4">
                20, 100 Feet Rd, Vivek Nagar,
                <br className="hidden md:inline-block" /> Chandra Reddy Layout,
                AVS Layout Ejipura,
                <br className="hidden md:inline-block" /> Bengaluru, Karnataka
                560095
              </div>

              <div>
                <a
                  href="mailto:ping@criodo.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  ping@criodo.com
                </a>
              </div>
            </div>
            <div>
              <h6>Community Programs</h6>
              <div>
                <Link to="/ibelieveindoing/">#IBelieveInDoing Challenge </Link>
              </div>
              <div>
                <Link to="/crio-winter-of-doing/">Crio Winter of Doing</Link>
              </div>
              <div>
                <Link to="/projects/">Crio Projects</Link>
              </div>
            </div>
            <div className="flex flex-col gap-y-4">
              <div>
                <h6>Career Programs</h6>
                <div>
                  <Link to="/software-development-fellowship-program/">
                    Crio Fellowship
                  </Link>{' '}
                </div>{' '}
              </div>
              <div>
                {' '}
                <h6>For Business</h6>{' '}
                <div>
                  <a href="/recruit">Hire from Crio</a>
                </div>
                <div>
                  <a href="/onboard">Crio Onboard</a>
                </div>
              </div>
            </div>
            <div>
              {' '}
              <h6>Crio</h6>
              <div>
                <Link to="/about-us">About</Link>
              </div>
              <div>
                <a
                  href="https://www.crio.do/blog/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>{' '}
              </div>{' '}
              <div>
                <a href="/testimonials">Testimonials</a>{' '}
              </div>{' '}
              <div>
                <a href="/terms" target="_blank">
                  Terms of Use
                </a>
              </div>{' '}
              <div>
                <a href="/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
          <div className="block items-center justify-center md:flex">
            {' '}
            <a
              href={'https://www.linkedin.com/company/criodo/' + str}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={['fab', 'linkedin']}
                style={{
                  marginRight: '2rem',
                  // marginTop: "-4px",
                  fontSize: '30px',
                }}
                // size="lg"
              />{' '}
            </a>
            <a
              href={'https://www.facebook.com/criodo' + str}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={['fab', 'facebook']}
                style={{
                  marginRight: '2rem',
                  // marginTop: "-4px",
                  fontSize: '30px',
                }}
                // size="lg"
              />{' '}
            </a>
            <a
              href={'https://www.instagram.com/crio.do/' + str}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={['fab', 'instagram']}
                style={{
                  marginRight: '2rem',
                  // marginTop: "-4px",
                  fontSize: '30px',
                }}
                // size="lg"
              />{' '}
            </a>
            <a
              href={'https://twitter.com/crio_do/' + str}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={['fab', 'twitter']}
                style={{
                  // color: "#999",
                  marginRight: '2rem',
                  // marginTop: "-4px",
                  fontSize: '30px',
                }}
                // size="lg"
              />{' '}
            </a>
            <a
              href={
                'https://www.youtube.com/channel/UCE1_bZxswCCARhhVeSQLuew/' +
                str
              }
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={['fab', 'youtube']}
                style={{
                  // color: "#999",
                  marginRight: '2rem',
                  // marginTop: "-4px",
                  fontSize: '30px',
                }}
                // size="lg"
              />{' '}
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
